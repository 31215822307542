import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	width: 50rem;
	z-index: 1;
	position: absolute;
	top: 27%;
	left: 3%;
	margin: 0 auto;
	@media screen and (max-width: 850px) {
		position: relative;
		width: 100%;
		max-width: 55rem;
		padding: 0 8% 0 3%;
		padding-bottom: 2rem;
	}
	/* && path {
		fill: red;
	} */
`;

export default function Logo() {
	return (
		<Container>
			{/* <svg
				viewBox='0 0 924 592'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M119.77 139.57H65.2781L55.1218 197.676H26.5085L51.2156 55.4882H141.255L137.055 79.2186H75.7273L69.282 115.937H123.969L119.77 139.57ZM260.2 79.2186H218.012L197.505 197.676H168.891L189.399 79.2186H147.7L151.899 55.4882H264.399L260.2 79.2186ZM328.657 168.379H279.048L264.399 197.676H232.856L308.833 55.4882H335.298L362.348 197.676H333.149L328.657 168.379ZM290.766 144.648H325.044L317.036 91.7186L290.766 144.648ZM490.473 150.215C489.367 160.045 486.014 168.769 480.415 176.387C474.816 183.939 467.524 189.766 458.54 193.867C449.555 197.904 439.855 199.824 429.438 199.629C420.063 199.433 411.893 197.285 404.927 193.183C397.96 189.017 392.459 183.027 388.423 175.215C384.451 167.337 382.205 158.288 381.684 148.066C381.229 140.058 382.042 130.098 384.126 118.183C386.274 106.269 390.05 95.69 395.454 86.4452C400.923 77.2004 407.4 69.8762 414.887 64.4725C425.434 56.9205 437.479 53.2746 451.02 53.535C465.734 53.7955 477.387 58.3527 485.981 67.2069C494.64 75.996 499.393 88.2356 500.239 103.926L471.723 103.828C471.723 94.8436 469.933 88.2681 466.352 84.1014C462.772 79.9348 457.205 77.7212 449.653 77.4608C439.887 77.1353 431.847 80.6184 425.532 87.91C419.282 95.2017 415.083 105.846 412.934 119.844C410.851 133.45 409.94 143.639 410.2 150.41C410.525 159.004 412.446 165.351 415.962 169.453C419.477 173.489 424.393 175.605 430.708 175.801C439.432 176.126 446.398 174.075 451.606 169.648C456.88 165.156 460.363 158.743 462.055 150.41L490.473 150.215ZM614.887 197.676H587.153L547.602 102.851L531.098 197.676H502.485L527.192 55.4882H554.829L594.477 150.508L610.981 55.4882H639.497L614.887 197.676ZM671.04 197.676H642.427L667.036 55.4882H695.649L671.04 197.676ZM752.387 140.937L733.637 158.32L726.802 197.676H698.188L722.895 55.4882H751.509L740.571 118.183L755.122 101.777L797.895 55.4882H835.2L773.774 120.137L810.395 197.676H777.778L752.387 140.937Z'
					fill='var(--accent-light)'
				/>
				<path
					d='M110.992 288.908H54.7424V347.014H25.4456V204.826H118.024V228.557H54.7424V265.275H110.992V288.908ZM241.6 228.557H198.046V347.014H168.749V228.557H125.78V204.826H241.6V228.557ZM324.552 317.717H273.185L263.419 347.014H232.267L285.196 204.826H312.345L365.567 347.014H334.415L324.552 317.717ZM281.095 293.986H316.642L298.771 240.764L281.095 293.986ZM485.921 299.65C484.815 314.95 479.15 326.994 468.929 335.783C458.773 344.572 445.361 348.967 428.695 348.967C410.466 348.967 396.11 342.847 385.628 330.607C375.212 318.303 370.003 301.441 370.003 280.021V271.33C370.003 257.658 372.412 245.614 377.23 235.197C382.048 224.78 388.916 216.805 397.835 211.271C406.82 205.672 417.236 202.873 429.085 202.873C445.492 202.873 458.708 207.267 468.734 216.057C478.76 224.846 484.554 237.183 486.117 253.068H456.82C456.104 243.889 453.532 237.248 449.105 233.146C444.743 228.98 438.07 226.896 429.085 226.896C419.32 226.896 411.996 230.412 407.113 237.443C402.295 244.409 399.821 255.249 399.691 269.963V280.705C399.691 296.07 402.002 307.3 406.624 314.396C411.312 321.493 418.669 325.041 428.695 325.041C437.744 325.041 444.483 322.99 448.91 318.889C453.402 314.722 455.973 308.309 456.624 299.65H485.921ZM618.385 347.014H589.088L532.056 253.459V347.014H502.76V204.826H532.056L589.185 298.576V204.826H618.385V347.014ZM672.723 347.014H643.426V204.826H672.723V347.014ZM742.1 289.982L726.866 306.389V347.014H697.569V204.826H726.866V269.279L739.756 251.603L775.987 204.826H812.022L761.534 268.01L813.487 347.014H778.624L742.1 289.982Z'
					fill='var(--accent-light)'
				/>
				<path
					d='M94.2205 438.435H39.7283L29.572 496.541H0.95874L25.6658 354.353H115.705L111.506 378.083H50.1775L43.7322 414.802H98.4197L94.2205 438.435ZM235.05 378.083H192.863L172.355 496.541H143.742L164.249 378.083H122.55L126.749 354.353H239.249L235.05 378.083ZM303.907 467.244H254.298L239.649 496.541H208.106L284.083 354.353H310.548L337.599 496.541H308.399L303.907 467.244ZM266.017 443.513H300.294L292.286 390.583L266.017 443.513ZM466.124 449.08C465.017 458.91 461.664 467.634 456.065 475.251C450.466 482.804 443.174 488.63 434.19 492.732C425.206 496.768 415.505 498.689 405.088 498.494C395.713 498.298 387.543 496.15 380.577 492.048C373.611 487.882 368.109 481.892 364.073 474.08C360.101 466.202 357.855 457.153 357.335 446.931C356.879 438.923 357.693 428.962 359.776 417.048C361.924 405.134 365.7 394.555 371.104 385.31C376.573 376.065 383.051 368.741 390.538 363.337C401.085 355.785 413.129 352.139 426.67 352.4C441.384 352.66 453.038 357.218 461.631 366.072C470.29 374.861 475.043 387.1 475.889 402.791L447.374 402.693C447.374 393.708 445.583 387.133 442.003 382.966C438.422 378.8 432.855 376.586 425.303 376.326C415.538 376 407.497 379.483 401.182 386.775C394.932 394.067 390.733 404.711 388.585 418.708C386.501 432.315 385.59 442.504 385.85 449.275C386.176 457.869 388.096 464.216 391.612 468.318C395.128 472.354 400.043 474.47 406.358 474.666C415.082 474.991 422.048 472.94 427.256 468.513C432.53 464.021 436.013 457.608 437.706 449.275L466.124 449.08ZM590.938 496.541H563.203L523.652 401.716L507.149 496.541H478.535L503.242 354.353H530.879L570.527 449.373L587.031 354.353H615.547L590.938 496.541ZM647.49 496.541H618.877L643.486 354.353H672.099L647.49 496.541ZM729.238 439.802L710.488 457.185L703.652 496.541H675.038L699.745 354.353H728.359L717.421 417.048L731.972 400.642L774.745 354.353H812.05L750.624 419.001L787.245 496.541H754.628L729.238 439.802Z'
					fill='var(--accent-light)'
				/>
			</svg> */}
			<svg
				viewBox='0 0 837 536'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<g clipPath='url(#clip0)'>
					<path
						d='M119.77 139.57H65.2781L55.1218 197.676H26.5085L51.2156 55.4882H141.255L137.055 79.2186H75.7273L69.282 115.937H123.969L119.77 139.57ZM260.2 79.2186H218.012L197.505 197.676H168.891L189.399 79.2186H147.7L151.899 55.4882H264.399L260.2 79.2186ZM328.657 168.379H279.048L264.399 197.676H232.856L308.833 55.4882H335.298L362.348 197.676H333.149L328.657 168.379ZM290.766 144.648H325.044L317.036 91.7186L290.766 144.648ZM490.473 150.215C489.367 160.045 486.014 168.769 480.415 176.387C474.816 183.939 467.524 189.766 458.54 193.867C449.555 197.904 439.855 199.824 429.438 199.629C420.063 199.433 411.893 197.285 404.927 193.183C397.96 189.017 392.459 183.027 388.423 175.215C384.451 167.337 382.205 158.288 381.684 148.066C381.229 140.058 382.042 130.098 384.126 118.183C386.274 106.269 390.05 95.69 395.454 86.4452C400.923 77.2004 407.4 69.8762 414.887 64.4725C425.434 56.9205 437.479 53.2746 451.02 53.535C465.734 53.7955 477.387 58.3527 485.981 67.2069C494.64 75.996 499.393 88.2356 500.239 103.926L471.723 103.828C471.723 94.8436 469.933 88.2681 466.352 84.1014C462.772 79.9348 457.205 77.7212 449.653 77.4608C439.887 77.1353 431.847 80.6184 425.532 87.91C419.282 95.2017 415.083 105.846 412.934 119.844C410.851 133.45 409.94 143.639 410.2 150.41C410.525 159.004 412.446 165.351 415.962 169.453C419.477 173.489 424.393 175.605 430.708 175.801C439.432 176.126 446.398 174.075 451.606 169.648C456.88 165.156 460.363 158.743 462.055 150.41L490.473 150.215ZM614.887 197.676H587.153L547.602 102.851L531.098 197.676H502.485L527.192 55.4882H554.829L594.477 150.508L610.981 55.4882H639.497L614.887 197.676ZM671.04 197.676H642.427L667.036 55.4882H695.649L671.04 197.676ZM752.387 140.937L733.637 158.32L726.802 197.676H698.188L722.895 55.4882H751.509L740.571 118.183L755.122 101.777L797.895 55.4882H835.2L773.774 120.137L810.395 197.676H777.778L752.387 140.937Z'
						fill='var(--accent-light)'
					/>
					<path
						d='M110.992 291.908H54.7424V350.014H25.4456V207.826H118.024V231.557H54.7424V268.275H110.992V291.908ZM241.6 231.557H198.046V350.014H168.749V231.557H125.78V207.826H241.6V231.557ZM324.552 320.717H273.185L263.419 350.014H232.267L285.196 207.826H312.345L365.567 350.014H334.415L324.552 320.717ZM281.095 296.986H316.642L298.771 243.764L281.095 296.986ZM485.921 302.65C484.815 317.95 479.15 329.994 468.929 338.783C458.773 347.572 445.361 351.967 428.695 351.967C410.466 351.967 396.11 345.847 385.628 333.607C375.212 321.303 370.003 304.441 370.003 283.021V274.33C370.003 260.658 372.412 248.614 377.23 238.197C382.048 227.78 388.916 219.805 397.835 214.271C406.82 208.672 417.236 205.873 429.085 205.873C445.492 205.873 458.708 210.267 468.734 219.057C478.76 227.846 484.554 240.183 486.117 256.068H456.82C456.104 246.889 453.532 240.248 449.105 236.146C444.743 231.98 438.07 229.896 429.085 229.896C419.32 229.896 411.996 233.412 407.113 240.443C402.295 247.409 399.821 258.249 399.691 272.963V283.705C399.691 299.07 402.002 310.3 406.624 317.396C411.312 324.493 418.669 328.041 428.695 328.041C437.744 328.041 444.483 325.99 448.91 321.889C453.402 317.722 455.973 311.309 456.624 302.65H485.921ZM618.385 350.014H589.088L532.056 256.459V350.014H502.76V207.826H532.056L589.185 301.576V207.826H618.385V350.014ZM672.723 350.014H643.426V207.826H672.723V350.014ZM742.1 292.982L726.866 309.389V350.014H697.569V207.826H726.866V272.279L739.756 254.603L775.987 207.826H812.022L761.534 271.01L813.487 350.014H778.624L742.1 292.982Z'
						fill='var(--accent-light)'
					/>
					<path
						d='M94.2205 444.435H39.7283L29.572 502.541H0.95874L25.6658 360.353H115.705L111.506 384.083H50.1775L43.7322 420.802H98.4197L94.2205 444.435ZM235.05 384.083H192.863L172.355 502.541H143.742L164.249 384.083H122.55L126.749 360.353H239.249L235.05 384.083ZM303.907 473.244H254.298L239.649 502.541H208.106L284.083 360.353H310.548L337.599 502.541H308.399L303.907 473.244ZM266.017 449.513H300.294L292.286 396.583L266.017 449.513ZM466.124 455.08C465.017 464.91 461.664 473.634 456.065 481.251C450.466 488.804 443.174 494.63 434.19 498.732C425.206 502.768 415.505 504.689 405.088 504.494C395.713 504.298 387.543 502.15 380.577 498.048C373.611 493.882 368.109 487.892 364.073 480.08C360.101 472.202 357.855 463.153 357.335 452.931C356.879 444.923 357.693 434.962 359.776 423.048C361.924 411.134 365.7 400.555 371.104 391.31C376.573 382.065 383.051 374.741 390.538 369.337C401.085 361.785 413.129 358.139 426.67 358.4C441.384 358.66 453.038 363.218 461.631 372.072C470.29 380.861 475.043 393.1 475.889 408.791L447.374 408.693C447.374 399.708 445.583 393.133 442.003 388.966C438.422 384.8 432.855 382.586 425.303 382.326C415.538 382 407.497 385.483 401.182 392.775C394.932 400.067 390.733 410.711 388.585 424.708C386.501 438.315 385.59 448.504 385.85 455.275C386.176 463.869 388.096 470.216 391.612 474.318C395.128 478.354 400.043 480.47 406.358 480.666C415.082 480.991 422.048 478.94 427.256 474.513C432.53 470.021 436.013 463.608 437.706 455.275L466.124 455.08ZM590.938 502.541H563.203L523.652 407.716L507.149 502.541H478.535L503.242 360.353H530.879L570.527 455.373L587.031 360.353H615.547L590.938 502.541ZM647.49 502.541H618.877L643.486 360.353H672.099L647.49 502.541ZM729.238 445.802L710.488 463.185L703.652 502.541H675.038L699.745 360.353H728.359L717.421 423.048L731.972 406.642L774.745 360.353H812.05L750.624 425.001L787.245 502.541H754.628L729.238 445.802Z'
						fill='var(--accent-light)'
					/>
				</g>
				<defs>
					<clipPath id='clip0'>
						<rect
							width='837'
							height='536'
							fill='var(--accent-light)'
						/>
					</clipPath>
				</defs>
			</svg>
		</Container>
	);
}
